import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WathsappIcon from '@mui/icons-material/Facebook';
import { IoLogoWhatsapp } from 'react-icons/io5';
import InstagramFooter from '../assets/Instagram_footer.png';
import FacebookFooter from '../assets/Facebook_footer.png';
import LinkhubLogo from '../assets/Linkhub_Logo.png';
import { Link } from 'react-router-dom';
// import theme from '../../themes/themeConfig';

const Footer = () => {
  const LogoCompartido = process.env.REACT_APP_LOGOCOMPARTIDO;
  return (
    <>
      <CssBaseline />
      <Box
        // style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}
        component="footer"
        sx={{
          mt: 'auto',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'left',
          backgroundColor: 'black',
          color: 'white',
        }}
      >
        <Box display="flex" sx={{ flexDirection:{xs:'column', md:'row'}, padding:'20px', paddingY: { xs:'30px', md:'10px'}}} justifyContent={'space-between'} >
        <img src={LogoCompartido} alt="LogoCompartido" style={{ width: 'auto', height: 'auto'}} />
        </Box>
        <Box display="flex" sx={{ flexDirection:{xs:'column', md:'row'}, padding:'20px', paddingY: { xs:'30px', md:'10px'}}} justifyContent={'space-between'} >
          <Box display="flex" flexDirection="column" sx={{ flexBasis: { xs: 'auto', md:'291px'}, gap: {xs:'20px', md:'10px'} }}>
            <Box display="flex" flexDirection="column" gap={{ xs:2, md:1}} sx={{ alignItems: { xs:'center', md:'start'} }}>
              <Typography sx={{ fontSize: { xs:'13px', md:'18px'}, fontWeight: 700, letterSpacing: 0 }} color="white">
                Comprometidos con tu desarrollo
              </Typography>
            </Box>
            <Typography sx={{  textAlign: {xs:'center', md:'left'}, margin:{xs:'0 auto', md:0}, width:{ xs:'270px', md:'auto'}, fontSize: {xs:'13px', md:'18px'}, color: 'white', letterSpacing: 0 }}>Somos una empresa especializada en servicios de marketing y comercio digital B2B</Typography>
          </Box>
         
          <Box display="flex" flexDirection="column" alignItems={{ xs:'center', md:'start'}} gap={'10px'} sx={{ fontWeight:100, fontSize: {xs:'15px', md:'20px'}, marginTop: {xs: '50px', md:0}, color:{ xs:'white', md:'white'} }}>
          <Typography sx={{ fontSize: { xs:'13px', md:'18px'}, fontWeight: 700, letterSpacing: 0 }} color="white">
                Centro de ayuda
              </Typography>
            <Link to="/faq">
              <li>Preguntas frecuentes</li>
            </Link>
            <Link to="/experiencia">
              <li>Ayúdanos a mejorar</li>
            </Link>
            <li>Tutoriales</li>
          </Box>
          <Box display="flex" flexDirection="column" alignItems={{ xs:'center', md:'start'}} gap={'10px'} sx={{ fontWeight:100, fontSize: {xs:'15px', md:'20px'}, marginTop: {xs: '50px', md:0}, color:{ xs:'white', md:'white'} }}>
          <Typography sx={{ fontSize: { xs:'13px', md:'18px'}, fontWeight: 700, letterSpacing: 0 }} color="white">
                Información Legal
              </Typography>
            <Link to="/faq">
              <li>Políticas de privacidad</li>
            </Link>
            <Link to="/experiencia">
              <li>Libro de reclamaciones</li>
            </Link>
          </Box>
          {/* <Box display="flex" flexDirection="column" gap={{ xs:'40px', md:'55px'}} alignItems="center" justifyContent="end" sx={{ marginTop:{xs:'62px', md:0}}}> */}
          <Box display="flex" flexDirection="column" alignItems={{ xs:'center', md:'start'}} gap={'10px'} sx={{ fontWeight:100, fontSize: {xs:'15px', md:'20px'}, marginTop: {xs: '50px', md:0}, color:{ xs:'white', md:'white'} }}>
            <Typography sx={{ fontSize: { xs:'13px', md:'18px'}, fontWeight: 700, letterSpacing: 0 }} color="white">
                Contáctanos
              </Typography>
              <Box display="flex" justifyContent="center" gap={{ xs:'28px', md:'25px'}}>
              <a href="https://www.facebook.com" target="_blank" >
                <Box component="img" src={FacebookFooter} sx={{ width:{xs:'9px', md:'auto'}, height:{xs:'20px', md:'auto'}}} />

              </a>
              <a href="https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(`Hola Vedoraplus ${vedoraEnvironment}`)}`" target="_blank" rel="noopener noreferrer">
                  <IoLogoWhatsapp size={38} color="#FFFFFF" />
                </a>
              <a href="https://www.instagram.com" target="_blank">
                <Box component="img" src={InstagramFooter} sx={{ width:{xs:'21px', md:'auto'}, height:{xs:'21px', md:'auto'}}} />
              </a>
            </Box>
          
          </Box>
        </Box>

        <Box sx={{backgroundColor: 'var(--primary-color)', width: '100%', textAlign: 'center'}}> 

        
        <Typography sx={{ fontSize:{xs:'11px',md:'20px', margin: '10px'}}}>© Powered by Vedoraplus 2024 Todos los derechos son reservados</Typography>
        </Box>
        {/* <Typography component="div" color="primary">
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              style={{
                width: '30px',
              }}
              src={'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/icons/whatsapp.png'}
              alt=""
            />
            <Box display="flex" justifyContent="center" color="white" fontWeight="600" fontSize="14px" ml={1}>
              +51 977 774 804
            </Box>
            <Box display="flex" justifyContent="center" color="white" ml={1} p={1}>
              <Box display="flex" justifyContent="center" px={1}>
                <FacebookIcon fontSize="large" />
              </Box>
              <Box display="flex" justifyContent="center" px={1}>
                <InstagramIcon fontSize="large" />
              </Box>
            </Box>
          </Box>
        </Typography>

        <Typography component="div" color="primary">
          <Box display="flex" justifyContent="center" color="white" bgcolor="#192638" fontWeight="400" fontSize="11px" p={1}>
            © Powered by
            <Box display="flex" justifyContent="center" color="#FFF" fontWeight="600" fontSize="11px" ml="4px">
              linkhub.ai
            </Box>
          </Box>
        </Typography> */}
      </Box>
      <Box
        // style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}
        component="footer"
        sx={{
          mt: 'auto',
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: '40px',
          backgroundColor: 'white',
          color: 'white',
        }}
      >

      </Box>
    </>
  );
};

export default Footer;
