import { Box, Typography, Button, ButtonGroup, Input, Snackbar, Alert, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import LinesEllipsis from 'react-lines-ellipsis';
import { toTitleCase } from '../utils';

import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { getPriceWithDiscount, getGanancia } from '../utils/getPriceWithDiscount';
const Product = (props) => {
  //   const chartProducts = useSelector((store) => store.chart.products);
  
  const navigate = useNavigate()
  const { chart, addProductToChartAction, deleteProductFromChartAction, substractProductToChartAction, updateProductToChartAction } = useCart();

  const [productAmount, setProductAmount] = useState(chart.products.find((prod) => (prod.product.code === props.product.code ? prod.amount : 0)));
  const [isSelected, setIsSelected] = useState(chart.products.find((prod) => prod.product.code === props.product.code) ? true : false);
  const [noStock, setNoStock] = useState(false);
  const [openStockAlert, setOpenStockAlert] = useState(false);

  const selectProduct = (product, newProductAmount) => {
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    if (chartProduct.length > 0) {
      setProductAmount(chartProduct[0].amount);
    } else {
      if(product.stockAmount === 1) {
        setNoStock(true);
      }
      setProductAmount(1);
      const priceWithDiscount = getPriceWithDiscount(product, 1)
      updateProductToChartAction(props.product, 1, priceWithDiscount);
    }
    setIsSelected(true);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas pequeñas
  const productName = isSmallScreen ? toTitleCase(props.product.shortName) : toTitleCase(props.product.name);


  useEffect(() => {
    let exists = chart.products.find((prod) => prod.product.code === props.product.code);
    //console.log('buscado:',exists);
    if (exists) {
      const notStockAvaible = exists.amount >= props.product.stockAmount 
      setNoStock(notStockAvaible)
      setProductAmount(exists.amount);
    }
  }, [chart.products]);

  
  const addProductToChart = (product, newProductAmount) => {
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);

    if (chartProduct.length > 0 && newProductAmount === chartProduct[0].amount) {
      newProductAmount = newProductAmount + 1;
      if (newProductAmount === product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      } else if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(product.stockAmount);
        const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
        updateProductToChartAction(props.product, product.stockAmount,priceWithDiscount);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      }
    } else {
      if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      }
    }
  };

  const substractProductToChart = (product, newProductAmount) => {
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    console.log('buscadox:',chartProduct);
    if (newProductAmount === chartProduct[0].amount) {
      if (newProductAmount - 1 < 1) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(props.product);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        newProductAmount = newProductAmount - 1;
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      }
    } else {
      if (newProductAmount - 1 < 0) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(props.product);
      } else {
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(props.product, newProductAmount,priceWithDiscount);
      }
    }
  };

  const handleChangeInput = (product, e) => {
    if (Number(e.target.value) >= product.stockAmount) {
      setNoStock(true);
      setOpenStockAlert(true);
      setProductAmount(product.stockAmount);
      const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
      updateProductToChartAction(product, product.stockAmount,priceWithDiscount);
    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductAmount(e.target.value);
      const priceWithDiscount = getPriceWithDiscount(product, e.target.value)
      updateProductToChartAction(product, e.target.value,priceWithDiscount);
    }
  };

  const handleUnitsChange = (product, quantity) => {
    setIsSelected(true);
    if (Number(quantity) >= product.stockAmount){
      setNoStock(true);
      setOpenStockAlert(true);
      const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
      updateProductToChartAction(product, product.stockAmount,priceWithDiscount);

    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductAmount(Number(quantity));
      const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
      updateProductToChartAction(product, Number(quantity),priceWithDiscount);
      // addProductToChart(product, Number(quantity));
    }
  }

  const handleChangeInputBlur = (product, e) => {
    if (Number(e.target.value) <= 0) {
      setIsSelected(false);
      setProductAmount(0);
      deleteProductFromChartAction(props.product);
    }
  };

  const priceWithDiscount = useMemo(() => {
    return getPriceWithDiscount(props.product, productAmount)
  }, [props.product, productAmount])



  const handleClose = () => {
    setOpenStockAlert(false);
  };
  
  return (
    <>
      {/* <Paper
        elevation={4}
        sx={{
          margin: 1,
        }}
        style={{
          position: 'relative',
          border: props.product.sponsored === true || props.product.sponsoredVip === true ? '2px solid #21aee0' : null,
        }}
      > */}
      <Box
        data-product-id={props.product.code}
        data-product-price={priceWithDiscount?.toFixed(1)}
        sx={{
          padding: {xs: '10px', md:'24px 25px'},
          height: { xs: '340px', sm: '400px', md: '500px' },
          margin: '0',
          fontSize: '1rem',
          position: 'relative',
          background: '#FDFDFD',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between',
          alignItems: 'stretch',
          borderRadius:'5px',
          // border: '1px solid rgb(232, 232, 232)',
          cursor: 'default',
          borderRadius: '12px 12px 12px 12px',
          boxShadow: '6px 12px 24px 6px #0000001F'

        }}
      >
        {/* {(props.product.sponsored === true || props.product.sponsoredVip === true) && (
          <div
            style={{
              position: 'absolute',
              top: 30,
              left: -35,
              textAlign: 'center',
              display: 'table',
              padding: '5px 10px',
              transform: 'rotate(-90deg)',
            }}
          >
            <p style={{ fontSize: 13, color: '#b0b0b0' }}>Patrocinado</p>
          </div>
        )} */}

        <Box display="flex" flexDirection="column">
          
          <div
            style={{
              marginTop: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              component="img"
              src={props.product.imageUrl?.[0]?.imgUrl !== undefined ? 'https://images-dev.vedoraplus.com/komilon/' + props.product.imageUrl?.[0]?.imgUrl + '.webp': '/assets/images/NoDisponible.png'} 
              alt={'Imagen no disponible2'}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/assets/images/NoDisponible.png';
              }}
              sx={{
                // maxWidth: { xs: '134px', sm:'180px', md: '220px' },
                // maxHeight: { xs: '134px', sm:'180px',md: '220px' },
                margin:'0 auto',
                marginTop:{xs:'10px', md:0},
                width: { xs: '120px', sm:'180px', md: '220px' },
                height:{ xs: '120px', sm:'180px', md: '220px' },
                // minHeight: '134px',
                // minWidth: '134px',
              }}
              onClick={() => navigate(`/tienda/producto/${props.product.code}`)}
            />
          </div>
          <div style={{backgroundColor: 'var(--primary-50-color)', borderRadius: '8px', padding: '8px'}}>              
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >        
              <svg style={{margin: '0px 5px'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.44737 11.4999H8.55263V7.2631H7.44737V11.4999ZM8 6.00199C8.16861 6.00199 8.30996 5.94495 8.42405 5.83086C8.53814 5.71677 8.59518 5.57542 8.59518 5.40681C8.59518 5.23819 8.53814 5.09684 8.42405 4.98275C8.30996 4.86879 8.16861 4.81181 8 4.81181C7.83139 4.81181 7.69003 4.86879 7.57595 4.98275C7.46186 5.09684 7.40482 5.23819 7.40482 5.40681C7.40482 5.57542 7.46186 5.71677 7.57595 5.83086C7.69003 5.94495 7.83139 6.00199 8 6.00199ZM8.00129 14.9999C7.03308 14.9999 6.12302 14.8162 5.2711 14.4488C4.41919 14.0813 3.67818 13.5827 3.04805 12.9528C2.41793 12.3229 1.91903 11.5822 1.55134 10.7307C1.18378 9.87913 1 8.96932 1 8.00123C1 7.03302 1.18372 6.12296 1.55116 5.27104C1.9186 4.41913 2.41725 3.67811 3.04713 3.04799C3.67701 2.41787 4.41772 1.91897 5.26926 1.55128C6.12081 1.18372 7.03062 0.999939 7.99871 0.999939C8.96692 0.999939 9.87698 1.18366 10.7289 1.5511C11.5808 1.91854 12.3218 2.41719 12.9519 3.04707C13.5821 3.67695 14.081 4.41766 14.4487 5.2692C14.8162 6.12075 15 7.03056 15 7.99865C15 8.96686 14.8163 9.87692 14.4488 10.7288C14.0814 11.5807 13.5827 12.3218 12.9529 12.9519C12.323 13.582 11.5823 14.0809 10.7307 14.4486C9.87919 14.8162 8.96938 14.9999 8.00129 14.9999ZM8 13.8947C9.64561 13.8947 11.0395 13.3236 12.1816 12.1815C13.3237 11.0394 13.8947 9.64555 13.8947 7.99994C13.8947 6.35432 13.3237 4.96047 12.1816 3.81836C11.0395 2.67625 9.64561 2.1052 8 2.1052C6.35439 2.1052 4.96053 2.67625 3.81842 3.81836C2.67632 4.96047 2.10526 6.35432 2.10526 7.99994C2.10526 9.64555 2.67632 11.0394 3.81842 12.1815C4.96053 13.3236 6.35439 13.8947 8 13.8947Z" fill="var(--primary-color)" />
                      </svg>    
                <Typography
                  sx={{
                    fontSize: { xs: '11px', md: '15px' },
                    color: {xs: 'var(--primary-color)', md:'var(--primary-color)'},
                    textAlign: 'center', fontWeight: 700
                  }}
                >
                  {/* Ganancia: {props.product.margin.toFixed(1)}% */}
                  Ganancia: {((props.product.sugPrice * 100 / priceWithDiscount)-100).toFixed(1)}%
                </Typography>
              </div>
              
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '11px', md: '15px' },
                    color: {xs: '#6A6A6A', md:'#6A6A6A'},
                    textAlign: 'center',
                  }}
                >
                  Precio Sugerido: S/. { props.product.sugPrice.toFixed(1)}
                </Typography>            
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '8px', md: '10px' },
                    color: {xs: '#6A6A6A', md:'#6A6A6A'},
                    textAlign: 'center',
                  }}
                >
                  sku: { props.product.skuId}
                </Typography>            
              </div>
          </div>
        </Box>
        <Box  onClick={() => navigate(`/tienda/producto/${props.product.code}`)}>
          <Typography component="span" variant="body1" color={'#000000'} sx={{ letterSpacing:0, lineHeight:'25px'}}>
            <Box component="div" textOverflow="ellipsis" overflow="hidden" textAlign="center" fontSize={{ xs: 15, sm:18, md: 18 }} mt={1}  sx={{ lineHeight:1.1134,minHeight: { xs:'35px', md:'40px'}, maxHeight:{xs:'35px', md:'40px'}}}>
              <LinesEllipsis component='p' text={toTitleCase(productName) } lineHeight="1.134" maxLine="2" ellipsis="..." trimRight basedOn="letters" />
            </Box>
          </Typography>
          <Typography component="span" color={'#6A6A6A'} sx={{fontSize: { xs: '12px', md: '14px' }, visibility: props.product.measurementUnit ? 'visible' : 'hidden' }}>
            X {props.product.measurementUnit} UN
          </Typography>
        </Box>
      

        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ marginTop: {xs: '1px', md:'0px'}}} gap={2}>        
            {
              
              props.product.salePrice !== priceWithDiscount ? (
                <Typography component="span" color={'red'} sx={{ textAlign:'center', textDecoration:'line-through',
                fontSize:{xs:'14px', md:'18px'}, fontWeight:700}}>
                  S/{props?.product?.salePrice?.toFixed(1)}
                </Typography>
                  
              ): ""
            }

          <Typography component="span" color={'var(--primary-color)'} sx={{ textAlign:'center',fontSize:{xs:'14px', md:'18px'}, fontWeight:900}}>            
            S/{priceWithDiscount?.toFixed(1)}
            {/* S/{props.product.salePrice.toFixed(1)} */}
          </Typography>

          {/* <Typography component="span" color="primary">
            <p
              style={{
                textAlign: 'center',
                opacity: props.product.margin.toString().trim() !== '' && props.product.promotionRule.toString().trim() !== '' ? 1 : 0,
                fontSize: 12,
                marginTop: 10,
              }}
            >
              Comprando <span style={{ fontWeight: 'bold' }}>{props.product.promotionRule}</span> tu <br /> margen es <span style={{ fontWeight: 'bold' }}>{`${props.product.margin}%`}</span>
            </p>
          </Typography> */}
        </Box>        
        <Box >
          {isSelected === true ? (
             <Box>
             <Box>
              <ButtonGroup
              variant="contained"
              color="primary"
              sx={{
                display:'flex',
                justifyContent:'center',
                maxHeight: { xs:'38px', md:'48px'},
                backgroundColor:'#FDFDFD',
                boxShadow:'none',
                overflow:'hidden',
                '& button': {
                  border:'none',
                  flexBasis:{ xs:'38px', md:'48px'},
                  width:{xs:'38px!important', md:'48px!important'},
                  minWidth: {xs:'38px!important', md:'48px!important'},
                  minHeight: { xs:'38px', md:'48px'},
                  maxWidth: { xs:'38px', md:'48px'},
                  maxHeight: { xs:'38px', md:'48px'},
                  padding:0
                },
                '& .MuiInput-input': {
                  maxHeight: { xs:'38px', md:'48px'},
                  padding: '0',
                  flex:1,
                  textAlign: 'center',
                  color:'white',
                  outline:'none',
                  "&&:after": {
                    borderBottom: "none",
                    display:'none'
                  }
                },
                '& .MuiButtonGroup-grouped': {
                  minWidth: '5px',
                },
                '& .MuiBox-root': {
                  // padding: '6px',
                  flex: 1,
                  backgroundColor:'#ebcda5',
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
            >
              <Button id={`btn-substract-${props.product?.code}`} 
                  sx={{backgroundColor:'var(--primary-color)', ":hover": {
                      backgroundColor: 'var(--primary-color)',
                    },borderRadius:'32px', color:'white', fontSize:'30px', 
                    fontWeight:700, display:'flex', overflow:'hidden', border:'none'
                  }} 
                  onClick={() => substractProductToChart(props.product, productAmount)} fullWidth>
                <span style={{ padding: '0 0 6px 0px'}}> - </span>
              </Button>
              <Box >
                <Input
                  value={productAmount}
                  inputProps={{
                    'aria-label': 'description',                     
                    sx: { color:'#212121 !important' }                  
                  }}
                  sx={{ outline:'none', color: "#212121 !important", backgroundColor:'#FDFDFD', 
                    border:'none', '&:active': { outline:'none', border:'none'}, 
                    '&:focus': { outline:'none', border:'none'}}}
               
                  variant="outlined"
                  onChange={(e) => handleChangeInput(props.product, e)}
                  onBlur={(e) => handleChangeInputBlur(props.product, e)}
                />
              </Box>
              <Button id={`btn-add-${props.product?.code}`}  sx={{backgroundColor:'var(--primary-color)', ":hover": {
                    backgroundColor: 'var(--primary-color)',
                  }, borderRadius:'32px',color:'white', fontSize:'30px', fontWeight:700,display:'flex'}} onClick={() => addProductToChart(props.product, productAmount)} disabled={noStock} fullWidth>
                <span style={{ padding: '0 0 5px 0px'}}> + </span>
              </Button>
            </ButtonGroup>
            </Box>
            <Box >
            {typeof productAmount === 'number' && !isNaN(productAmount) && (
            <Typography
              sx={{
                fontSize: { xs: '11px', md: '15px' },
                color: { xs: '#6A6A6A', md: '#6A6A6A' },
                textAlign: 'center',
              }}
            >
              se añadió {productAmount} unidades
            </Typography>
          )}
          {typeof productAmount === 'number' && !isNaN(productAmount) &&  productAmount < props.product?.saleMin && (
            <Typography
              sx={{
                fontSize: { xs: '11px', md: '15px' },
                color: 'red',
                textAlign: 'center',
              }}
            >
              Mínimo {props.product?.saleMin} unidades
            </Typography>
          )}
            </Box>
            </Box>
          ) : (
            <Box>
              {props.product.stockAmount === 0 ? (
                <Button
                  variant="contained"
                  disabled
                  fullWidth
                  id={`btn-no-stock-${props.product?.code}`} 
                  sx={{
                    backgroundColor:'#21AEE0',
                    maxWidth: '100%',
                    minHeight: '48px',
                    '& span': {
                      fontSize: '0.75rem',
                    },
                    textTransform: 'none',
                  }}
                >
                  Sin Stock
                </Button>
              ) : (
                <Button id={`btn-addcart-${props.product?.code}`} textTransform='none'  variant="contained" color="primary" onClick={() => selectProduct(props.product, productAmount)} fullWidth 
                sx={{ padding: {xs: 0, md: 'inherit'},backgroundColor:'var(--primary-color)', ':hover': {
                    backgroundColor: 'var(--primary-color)',
                  }, height: { xs:'38px', md:'48px'}, borderRadius: '32px'}} >
                  <Box color="white" textTransform='none' sx={{ fontWeight: 900, fontSize:{xs:'0.81rem', md:'18px', }, letterSpacing:'0px'}}>
                    Agregar
                  </Box>
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar open={openStockAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          {'¡Solo ' + (props.product.stockAmount === 1 ? 'queda ' + props.product.stockAmount + ' unidad' : 'quedan ' + props.product.stockAmount + ' unidades') + ' en stock!'}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Product;
