import { Alert, Box, Snackbar, Typography, useMediaQuery, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useProducts } from '../context/ProductsContext';
import MainLayout from '../layout/MainLayout';
import theme from '../utils/ThemeConfig';
import { useUserAuth } from '../context/UserAuthContext';
import { useCart } from '../context/CartContext';
import { FaMinus, FaPlus } from 'react-icons/fa';
import SpinnerTeresa from '../components/SpinnerTeresa';
import ProductPromo from '../components/ProductPromo';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../styles/UserProductPage.css';
import { getPriceWithDiscount } from '../utils/getPriceWithDiscount';
import { useMemo } from 'use-memo-one';
import { AlertDefault } from "../components/new/AlertDefault";

export default function UserProductPage() {
  const navigate = useNavigate();
  const urlParamsProduct = useParams().productCode;
  const [productInfo, setProductInfo] = useState({});
  const { user } = useUserAuth();
  const { products, promotions, getProducts, getPromotions, getBanners, filteredProducts, filterProductsByBrand } = useProducts();
  const { chart, addProductToChartAction, deleteProductFromChartAction, substractProductToChartAction, updateProductToChartAction } = useCart();
  const [productAmount, setProductAmount] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [noStock, setNoStock] = useState(false);
  const [openStockAlert, setOpenStockAlert] = useState(false);
  const [productPromo, setProductPromo] = useState([]);

  const priceWithDiscount = useMemo(() => {
    return getPriceWithDiscount(productInfo, productAmount)
  }, [productInfo,productAmount])


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };
  const responsiveProduct = {
    allDevices: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,  // Mostrar una imagen a la vez en todas las pantallas
    },
  };

  // Estado para controlar la apertura del modal
  const [open, setOpen] = useState(false);
  
  // Funciones para abrir y cerrar el modal
  const handlePopupOpen = () => setOpen(true);
  const handlePopupClose = () => setOpen(false);

  const selectProduct = (product, newProductAmount) => {
    // // console.log('selectProduct INIT')
    // // console.log('productAmount: ', productAmount)
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    if (chartProduct.length > 0) {
      setProductAmount(chartProduct[0].amount);
    } else {
      setProductAmount(1);
      const priceWithDiscount = getPriceWithDiscount(product, 1)
      updateProductToChartAction(product, 1, priceWithDiscount);
    }
    setIsSelected(true);
  };

  const getUnitsOnCart = (product) => {
    const chartProduct = chart.products.filter((item) => item.product.code === product.code);

    if (chartProduct.length > 0) {
      setIsSelected(true)
      return setProductAmount(chartProduct[0].amount);
    }
    setIsSelected(false);
    setProductAmount(0);
  }

  const addProductToChart = (product, newProductAmount) => {
    // // console.log('addProductToChart INIT');
    // // console.log('newProductAmount: ', newProductAmount);
    // // console.log('productCode: ', product.code);
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    // console.log('chartProduct: ', chartProduct);
    if (newProductAmount === chartProduct[0].amount) {
      newProductAmount = newProductAmount + 1;
      if (newProductAmount === product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        // console.log('productAmount equal limitStock: ', newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);

      } else if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(product.stockAmount);
        // console.log('productAmount equal limitStock: ', product.stockAmount);
        const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
        updateProductToChartAction(product, product.stockAmount,priceWithDiscount);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        // console.log('productAmount equal: ', newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);
      }
    } else {
      if (newProductAmount >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(newProductAmount);
        // console.log('productAmount  NOT equal: ', newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(newProductAmount);
        // console.log('productAmount  NOT equal: ', newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);
      }
    }
  };

  const substractProductToChart = (product, newProductAmount) => {
    // // console.log('substractProductToChart INIT');
    // // console.log('newProductAmount: ', newProductAmount);
    // // console.log('productCode: ', product.code);
    let chartProduct = chart.products.filter((item) => item.product.code === product.code);
    // console.log('chartProduct: ', chartProduct);
    if (newProductAmount === chartProduct[0].amount) {
      if (newProductAmount - 1 < 1) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(productInfo);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        newProductAmount = newProductAmount - 1;
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);
      }
    } else {
      if (newProductAmount - 1 < 0) {
        setIsSelected(false);
        setProductAmount(0);
        deleteProductFromChartAction(productInfo);
      } else {
        setProductAmount(newProductAmount);
        const priceWithDiscount = getPriceWithDiscount(product, newProductAmount)
        updateProductToChartAction(product, newProductAmount,priceWithDiscount);
      }
    }
    // // console.log('productAmount a: ', productAmount)
  };

  const handleChangeInput = (product, e) => {
    // // console.log('e.target.value: ', e.target.value);
    // // console.log('e.target.value: ', typeof e.target.value);
    // // console.log('handleChangeInput ');
    if (e.target.value !== '' && Number(e.target.value) > 0) {
      if (Number(e.target.value) >= product.stockAmount) {
        setNoStock(true);
        setOpenStockAlert(true);
        setProductAmount(product.stockAmount);
        const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
        updateProductToChartAction(product, product.stockAmount,priceWithDiscount);
      } else {
        setNoStock(false);
        setOpenStockAlert(false);
        setProductAmount(Number(e.target.value));
        const priceWithDiscount = getPriceWithDiscount(product, e.target.value)
        updateProductToChartAction(product, e.target.value,priceWithDiscount);
      }
    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductAmount(0);
      addProductToChart(product, 0);
    }
  };

  const handleChangeInputBlur = (product, e) => {
    // // console.log('handleChangeInputBlur ');
    // // console.log('e.target.value: ', e.target.value);
    // // console.log('e.target.value: ', typeof e.target.value);
    if (Number(e.target.value) <= 0) {
      setIsSelected(false);
      setProductAmount(0);
      deleteProductFromChartAction(product);
    }
  };

  const lastImage = productInfo?.imageUrl?.[productInfo.imageUrl.length - 1]?.imgUrl;

  const handleUnitsChange = (product, quantity) => {
    setIsSelected(true);
    if (Number(quantity) >= product.stockAmount) {
      setNoStock(true);
      setOpenStockAlert(true);
      setProductAmount(product.stockAmount);
      const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
      updateProductToChartAction(product, product.stockAmount,priceWithDiscount);
    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductAmount(Number(quantity));
      const priceWithDiscount = getPriceWithDiscount(product, product.stockAmount)
      updateProductToChartAction(product, Number(quantity),priceWithDiscount);
      // addProductToChart(product, Number(quantity));
    }
  };
  

  const calcRentability = () => {
    let quantity = productAmount;
    if (quantity === 0) {
      quantity = 1;
    }

    return ((productInfo?.sugPrice - productInfo?.netoPrice) * quantity).toFixed(1);
  };

  const handleClose = () => {
    setOpenStockAlert(false);
  };

  const initialData = async () => {
    try {
      // await getPromotions(user);
      await getProducts(user);
      await getBanners(user)
    } catch (error) {
      console.error(error);
    }
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas pequeñas
  const productName = isSmallScreen ? productInfo?.shortName : productInfo?.name;

  const filterProductPromo = (code) => {
    const productPromotions = promotions
      .map((promo) => {
        const promotion = promo.products.some((product) => product.codeProduct === code);

        if (!promotion) return null;

        return promo;
      })
      .filter((promo) => promo);

    setProductPromo(productPromotions);
  };

  useEffect(() => {
    if (products.length === 0) {
      initialData();
    }    
  }, []);


  const handleClickHome = () => {
    return navigate('/tienda'); // Redirects to home page
  };
  const handleClickCar = () => {
    return navigate('/Carrito'); // Redirects to home page
  };
  useEffect(() => {
    if (products.length !== 0) {
      const info = products.find((item) => item.code === urlParamsProduct);
      if (Object.keys(info).length === 0) {
        return navigate('/tienda');
      }
      // filterProductsByBrand(products, info.marca, 'HOME');
      console.log('yyy', productInfo);
      setProductInfo(info);
      getUnitsOnCart(info);
      filterProductPromo(info.odooCode);
    }
  }, [products, urlParamsProduct]);


  return (
    <>
      {products.length === 0 && Object.keys(productInfo).length === 0 ? (
        <SpinnerTeresa />
      ) : (
        <MainLayout access='user'>
          <div
            className='l-container'
            style={{
              flexGrow: '1',
              backgroundColor: 'white',
              padding: theme.spacing(1),
              minHeight: 'calc(100vh - 64px - 76px)',
              marginTop: '50px',
            }}
          >
            <div className='productInfo-container'>
            <div className='productInfo-header'>
            <div className="carousel-container" style={{ width: '400px', height: '400px'}}>
                {productInfo?.imageUrl && productInfo.imageUrl.length > 0 ? (
                  <div className="carousel-container" style={{ width: '100%', height: '100%' }}>
                  <Carousel 
                  autoPlay={false} autoPlaySpeed={8000} responsive={responsiveProduct} partialVisbile={false} infinite={false}
                      >
                        {productInfo.imageUrl.map((item) => (
                          <div key={item.imgUrl} id={item.imgUrl} style={{ width: '100%', height: '100%' }}>
                            <img 
                                key={item.imgUrl} 
                                src={item.imgUrl !== undefined ? 'https://images-dev.vedoraplus.com/komilon/' + item.imgUrl + '.webp' : '/assets/images/NoDisponible.png'} 
                                alt="Product" 
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                              />
                          </div>
                        ))}
                  </Carousel>
                  </div>
                ) : (
                  <img
                    src={productInfo?.imageUrl?.[0]?.imgUrl !== undefined ? 'https://images-dev.vedoraplus.com/komilon/' + productInfo.imageUrl?.[0]?.imgUrl + '.webp' : '/assets/images/NoDisponible.png'}
                    alt={'Imagen no disponible44'}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/assets/images/NoDisponible.png';
                    }}
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </div>
              </div>

              <div className='productInfo-body'>
                <div className='productInfo-brand'>
                  {/* <img
                    // src={'https://storage.googleapis.com/' + process.env.REACT_APP_STORAGE_BUCKET_NAME + '/catalog/brands/' + productInfo.marca + '.png'}
                    src = {productInfo.imageUrl}
                    alt={productInfo.marca}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/assets/images/NoDisponible.png';
                    }}
                  /> */}
                </div>
                <div className='productInfo-name'>
                  <p>{productName}</p>
                </div>
                <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '8px', md: '10px' },
                    color: {xs: '#6A6A6A', md:'#6A6A6A'},
                    textAlign: 'center',
                  }}
                >
                  sku: { productInfo.skuId}
                </Typography>            
              </div>
                <Typography component="span" color={'#6A6A6A'} sx={{fontSize: { xs: '14px', md: '16px' }, visibility: productInfo?.measurementUnit ? 'visible' : 'hidden', textAlign: 'left' }}>
                  X {productInfo?.measurementUnit} UN
                </Typography>
                <div className='productInfo-price'>
                  <div className='price-venta'>
                    <span className='price-label'>P. Costo</span>
                    <div className='price-venta-value-container'>
                    {
                      productInfo?.salePrice  !== priceWithDiscount ? (
                        <span className='price-venta-value' style={{ color:'red', textDecoration:'line-through'}}>
                          S/{productInfo?.salePrice?.toFixed(1)}
                          </span>
                      ): ''
                    }
                    <span className='price-venta-value'>S/{priceWithDiscount?.toFixed(1)}</span>

                    </div>
                  </div>
                  <div className='price-sugprice'>
                    <p className='price-label'>P. Sugerido</p>
                    <p className='price-sugprice-value'>S/{productInfo?.sugPrice?.toFixed(1)}</p>
                  </div>
                  {/* <div className='price-rentabilidad'>
                    <p className='price-label'>Rentabilidad</p>
                    <p className='price-rentabilidad-value'>S/{calcRentability()}</p>
                  </div> */}
                </div>     
                <div style={{ display: 'flex', justifyContent: 'center',backgroundColor: 'white', borderRadius: '8px', padding: '8px', }}>
                    <svg style={{margin: '8px 5px'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.44737 11.4999H8.55263V7.2631H7.44737V11.4999ZM8 6.00199C8.16861 6.00199 8.30996 5.94495 8.42405 5.83086C8.53814 5.71677 8.59518 5.57542 8.59518 5.40681C8.59518 5.23819 8.53814 5.09684 8.42405 4.98275C8.30996 4.86879 8.16861 4.81181 8 4.81181C7.83139 4.81181 7.69003 4.86879 7.57595 4.98275C7.46186 5.09684 7.40482 5.23819 7.40482 5.40681C7.40482 5.57542 7.46186 5.71677 7.57595 5.83086C7.69003 5.94495 7.83139 6.00199 8 6.00199ZM8.00129 14.9999C7.03308 14.9999 6.12302 14.8162 5.2711 14.4488C4.41919 14.0813 3.67818 13.5827 3.04805 12.9528C2.41793 12.3229 1.91903 11.5822 1.55134 10.7307C1.18378 9.87913 1 8.96932 1 8.00123C1 7.03302 1.18372 6.12296 1.55116 5.27104C1.9186 4.41913 2.41725 3.67811 3.04713 3.04799C3.67701 2.41787 4.41772 1.91897 5.26926 1.55128C6.12081 1.18372 7.03062 0.999939 7.99871 0.999939C8.96692 0.999939 9.87698 1.18366 10.7289 1.5511C11.5808 1.91854 12.3218 2.41719 12.9519 3.04707C13.5821 3.67695 14.081 4.41766 14.4487 5.2692C14.8162 6.12075 15 7.03056 15 7.99865C15 8.96686 14.8163 9.87692 14.4488 10.7288C14.0814 11.5807 13.5827 12.3218 12.9529 12.9519C12.323 13.582 11.5823 14.0809 10.7307 14.4486C9.87919 14.8162 8.96938 14.9999 8.00129 14.9999ZM8 13.8947C9.64561 13.8947 11.0395 13.3236 12.1816 12.1815C13.3237 11.0394 13.8947 9.64555 13.8947 7.99994C13.8947 6.35432 13.3237 4.96047 12.1816 3.81836C11.0395 2.67625 9.64561 2.1052 8 2.1052C6.35439 2.1052 4.96053 2.67625 3.81842 3.81836C2.67632 4.96047 2.10526 6.35432 2.10526 7.99994C2.10526 9.64555 2.67632 11.0394 3.81842 12.1815C4.96053 13.3236 6.35439 13.8947 8 13.8947Z" fill="var(--primary-color)" />
                      </svg>    
                <Typography
                  sx={{
                    fontSize: { xs: '15px', md: '20px' },
                    color: {xs: 'var(--primary-color)', md:'var(--primary-color)'},
                    textAlign: 'center', fontWeight: 700
                  }}
                >
                  {/* Ganancia:2 {(productInfo?.margin ?? 0).toFixed(1)}% */}
                  Ganancia: {((productInfo.sugPrice * 100 / priceWithDiscount)-100).toFixed(1)}%
                </Typography>
                </div>          
                <div className='productInfo-button'>
                  {isSelected ? (
                    <Box>
                    <Box className='addcart-quantity fadeIn'>
                      <button className='addcart__add--operator' onClick={() => substractProductToChart(productInfo, productAmount)}>
                        <FaMinus size={20} />
                      </button>
                      <div className='addcart__input'>
                        <input type='number' value={productAmount} onChange={(e) => handleChangeInput(productInfo, e)} onBlur={(e) => handleChangeInputBlur(productInfo, e)} />
                      </div>
                      <button className='addcart__add--operator' onClick={() => addProductToChart(productInfo, productAmount)} disabled={noStock}>
                        <FaPlus size={20} />
                      </button>
                    </Box>
                    <Box >
                    {typeof productAmount === 'number' && !isNaN(productAmount) && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: { xs: '15px', md: '25px' },
                            color: { xs: '#6A6A6A', md: '#6A6A6A' },
                            textAlign: 'center',
                          }}
                        >
                          se añadió {productAmount} unidades
                        </Typography>
                        
                        
                        </Box>
                      )}
                      {typeof productAmount === 'number' && !isNaN(productAmount) &&  productAmount < productInfo?.saleMin && (
                        <Typography
                          sx={{
                            fontSize: { xs: '11px', md: '15px' },
                            color: 'red',
                            textAlign: 'center',
                          }}
                        >
                          Mínimo {productInfo?.saleMin} unidades
                        </Typography>
                      )}
                    </Box>
                    </Box>
                  ) : (
                    <button className='addcart-button fadeIn' onClick={() => selectProduct(productInfo, productAmount)}>
                      Agregar al carrito
                    </button>
                  )}
                </div>
                <Box >
            </Box>
            <div className='productInfo-footer'> 
            <Box 
          sx={{ 
            display: { xs: 'flex', md: 'flex' }, 
            flexDirection: 'row', // Esto asegura que los elementos se muestren en fila
            alignItems: 'center', // Opcional: Centra verticalmente si los elementos tienen diferentes alturas
            gap: '2px' // Opcional: Controla el espacio entre los elementos
          }}
          >
            <button className='addcart-buttonWhite fadeIn' 
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--primary-100-color)')}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                          onClick={handleClickHome}>
                            Seguir comprando
            </button>
            <button className='addcart-button2 fadeIn' 
                          onClick={handleClickCar}>
                           Siguiente
            </button>
              
          </Box>
            
            </div>
                <div className='productInfo-footer'> 
                  <Typography
                    component="a"
                    href="#"
                    onClick={handlePopupOpen}
                    sx={{
                      color: 'var(--primary-color)',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    ¿Tienes dudas sobre este producto?
                  </Typography>
                  {/* Modal de Material-UI */}
                  <Modal
                    open={open}
                    onClose={handlePopupClose}
                    aria-labelledby="popup-modal-title"
                    aria-describedby="popup-modal-description"
                  >
                    <Box 
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 4
                      }}
                    >
                      {/* Mostrar la última imagen del carrusel */}
                      {lastImage ? (
                        <img 
                          src={`https://images-dev.vedoraplus.com/komilon/${lastImage}.webp`}
                          alt="Última imagen del carrusel" 
                          style={{ width: '100%', height: 'auto' }} 
                          onClick={handlePopupClose} 
                        />
                      ) : (
                        <p>No hay imagen disponible</p>
                      )}
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>

            {productPromo.length !== 0 && <h4 className='product__promos-title'>Este producto en promoción</h4>}

            {productPromo.length > 1 ? (
              <Carousel className='product__promos' partialVisbile responsive={responsive} itemClass='list-container'>
                {/* <div className='product__promos'> */}
                {productPromo.map((item, index) => (
                  <div className='promotion__item' key={item.code}>
                    <div className='promotion__item--body'>
                      <ProductPromo product={productInfo} mechanic={item.mechanics} />
                    </div>
                  </div>
                ))}

                {/* </div> */}
              </Carousel>
            ) : (
              <div className='product-promo'>
                {productPromo.map((item, index) => (
                  <div className='promotion__item' key={item.code}>
                    <div className='promotion__item--body'>
                      <ProductPromo product={productInfo} mechanic={item.mechanics} />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <Snackbar open={openStockAlert} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity='warning'>
                {'¡Solo ' + (productInfo.stockAmount === 1 ? 'queda ' + productInfo.stockAmount + ' unidad' : 'quedan ' + productInfo.stockAmount + ' unidades') + ' en stock!'}
              </Alert>
            </Snackbar>
          </div>
        </MainLayout>
      )}
    </>
  );
}
