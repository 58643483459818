import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VedoraPlusIco from '../assets/vplus-ico.png';

import MainLayout from '../layout/MainLayout';

const PrivacyPoliciesWhatsAppPage = () => {
  const navigate = useNavigate();

  return (
    <MainLayout access="auth">
      <Box
        
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          '& > :not(style)': {
            m: 1,
          },
        }}
      >
        <Paper elevation={3} sx={{ p: '50px', justifyContent: 'normal' }} style={{width: '70%', textAlign: 'justify'}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', height:'50px' }}>
            <img alt="logo" src={VedoraPlusIco} />
          </Box>
          <Typography variant="h4" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)' }}>
            Política de Privacidad Vedoraplus
          </Typography>
          <Typography variant="h6" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)' }}>
            Última actualización 16/03/2024
          </Typography>

          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={12} align="center" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '300px' }}>
              <Typography variant="p" component="div" align="center" sx={{ color: 'rgb(49, 123, 146)', p: '10px' }}>
                

Agradecemos tu interés en Vedoraplus. <br/> Esta Política de Privacidad describe cómo Vedoraplus recopila, utiliza y protege la información personal que recopilamos de los usuarios de nuestra aplicación de WhatsApp Business "Demo Vedora".
<br/><br/>
1. Información que recopilamos:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.1 Información de perfil: Cuando utilizas nuestra Aplicación de WhatsApp Business, podemos recopilar cierta información de perfil proporcionada por WhatsApp, como tu nombre, número de teléfono y foto de perfil.
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.2 Mensajes y Conversaciones: La Aplicación puede acceder a los mensajes y conversaciones que se intercambian entre tú y nuestros representantes de servicio al cliente a través de WhatsApp Business.
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1.3 Información de Uso: Podemos recopilar información sobre cómo interactúas con nuestra Aplicación de WhatsApp Business, incluyendo las funciones que utilizas, los mensajes que envías y recibes, y otras actividades relacionadas con el uso de la aplicación.
<br/><br/>
2. Uso de la información:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.1 Propósitos de Servicio: Utilizamos la información recopilada para proporcionarte los servicios ofrecidos a través de nuestra Aplicación de WhatsApp Business, incluyendo el soporte al cliente y la atención al cliente.
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 2.2 Mejora de la Experiencia del Usuario: Utilizamos la información para mejorar y personalizar tu experiencia al utilizar nuestra Aplicación de WhatsApp Business, incluyendo la mejora de nuestras funciones y servicios.
<br/>
&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; 2.3 Comunicaciones: Podemos utilizar la información para comunicarnos contigo sobre tu uso de la Aplicación, responder a tus consultas y proporcionarte información relevante sobre nuestros productos y servicios.
<br/><br/>
3. Compartir de la información:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 3.1 Proveedores de Servicios: No compoartimos tu información.
<br/>
&nbsp; &nbsp; &nbsp; &nbsp;3.2 Cumplimiento Legal: Podemos divulgar información cuando sea necesario para cumplir con la ley, responder a solicitudes legales o proteger nuestros derechos legales, intereses comerciales o seguridad.
<br/><br/>
4. Seguridad de la información:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Tomamos medidas razonables para proteger la información personal que recopilamos y mantenerla segura contra accesos no autorizados, divulgación, alteración o destrucción.
<br/><br/>
5. Tus derechos:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Tienes derecho a eliminar tu información personal. Si deseas ejercer alguno de estos derechos, contáctanos a través de los medios proporcionados al final de esta Política de Privacidad.
<br/><br/>
6. Cambios en la Política de Privacidad:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cambios significativos en la Política de Privacidad publicando una versión actualizada en nuestra Aplicación de WhatsApp Business.
<br/><br/>
7. Contacto:
<br/>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Si tienes preguntas o inquietudes sobre nuestra Política de Privacidad o sobre el tratamiento de tu información personal, 
contáctanos en whatsapp@vedoraplus.com
              </Typography>
             
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </MainLayout>
  );
};

export default PrivacyPoliciesWhatsAppPage;
