import '../styles/CartItem.css';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GrClose } from 'react-icons/gr';
import { FaTrash } from 'react-icons/fa';

import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { useCart } from '../context/CartContext';
import { useMemo, useState } from 'react';
import { getPriceWithDiscount } from '../utils/getPriceWithDiscount';
import { FaMinus, FaPlus } from 'react-icons/fa';

export const CartItem = ({ item, editable = false }) => {
  const { updateProductToChartAction, deleteProductFromChartAction } = useCart();
  const [noStock, setNoStock] = useState(false);
  const [openStockAlert, setOpenStockAlert] = useState(false);
  const [productQuantity, setProductQuantity] = useState(item.amount)

  const handleQuantityButton = (quantity) => {
    if (Number(quantity) >= item.product.stockAmount) {
      setNoStock(true);
      setOpenStockAlert(true);
      setProductQuantity(item.product.stockAmount)
      const priceWithDiscount = getPriceWithDiscount(item.product, item.product.stockAmount)
      updateProductToChartAction(item.product, item.product.stockAmount, priceWithDiscount);
    } else {
      setNoStock(false);
      setOpenStockAlert(false);
      setProductQuantity(quantity)
      const priceWithDiscount = getPriceWithDiscount(item.product, quantity)
      updateProductToChartAction(item.product, Number(quantity),priceWithDiscount);
    }
  };

  const handleAddAndSubsQuantity = (quantity) => {
    if (Number(quantity) > 0) {
      return handleQuantityButton(quantity)
    }
    return deleteProductFromChartAction(item.product);
  };

  const handleRemoveFromCart = () => {
    deleteProductFromChartAction(item.product);
  }

  const handleQuantityChange = (e) => {
    handleQuantityButton(Number(e.target.value))
  }
  
  const handleQuantityBlur = (e) => {
    handleAddAndSubsQuantity(Number(e.target.value))
  }

  const priceWithDiscount = useMemo(() => {
    //console.log('memo items: ', item.product);
    return getPriceWithDiscount(item.product, productQuantity)
  }, [item.product, productQuantity])

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detecta pantallas pequeñas
  const productName = isSmallScreen ? toTitleCase(item.product.shortName) : toTitleCase(item.product.name);

  function toTitleCase(string) {
    if (string === null) {
      string = '-';
    } else {
      let splitStr = string.toLowerCase().split(' ');
  
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
        string = splitStr.join(' ');
      }
    }
    return string;
  }

  return (
    <Box className="card-item" sx={{ gap: { xs: '15px', md: editable ? '15px' : '50px' } }}>
      <div className="card-item-image">
        <img 
        src={item.product.imageUrl?.[0]?.imgUrl !== undefined ? 'https://images-dev.vedoraplus.com/komilon/' + item.product.imageUrl?.[0]?.imgUrl + '.webp': '/assets/images/NoDisponible.png'} 
        alt="Imagen Producto" />
      </div>
      {/* Detalle Productos CARRITO */}
      <div className="card-item-info">
        <p className="card-item-name">{productName}</p>   
        <Typography component="span" color={'#6A6A6A'} sx={{fontSize: { xs: '9px', md: '12px' }, textAlign: 'left' }}>
                sku:  {item.product.skuId}
        </Typography>
        <Typography component="span" color={'#6A6A6A'} sx={{fontSize: { xs: '14px', md: '16px' }, textAlign: 'left' }}>
        S/ {(priceWithDiscount).toFixed(1)} X {item.product.measurementUnit} UN
        </Typography>
        {typeof item.amount === 'number' && !isNaN(item.amount) &&  item.amount < item.product?.saleMin && (
            <Typography 
              sx={{
                fontSize: { xs: '11px', md: '15px' },
                color: 'red',
                textAlign: 'left',
                paddingTop: '0px',
              }}
            >
              Mínimo {item.product?.saleMin} unidades
            </Typography>
          )}
      </div>

      {!editable ? <p className="card-item-amount">x{item.amount}</p> : ''}

      {editable ? (
        <div className="card-item-remove">
          <button id={`btn-remove-${item?.product?.code}`} className="btn-remove-item" onClick={handleRemoveFromCart}>
            {/* <GrClose /> */}
            <FaTrash />
          </button>
        </div>
      ) : (
        ''
      )}
      
      <div className="card-item-values">
        <div className="card-item-prices">
          {
            (priceWithDiscount !== item?.product?.salePrice && editable) ?
            (
              <p className="card-item-price">S/{item.product?.salePrice?.toFixed(1)}</p>
            )
            :
            ''
          }

          <p className="card-item-discount">S/ {(priceWithDiscount * item.amount).toFixed(1)}</p>
        </div>
        {editable ? (
          <div className="card-item-quantity">
            <button id={`btn-subs-${item?.product?.code}`} onClick={() => handleAddAndSubsQuantity(item.amount - 1)} disabled={item.amount === 1}>
            <FaMinus size={20} />
            </button>
            <input className="cart-item-input" type="number" value={productQuantity} onBlur={handleQuantityBlur} onChange={handleQuantityChange}/>
            <button id={`btn-add-${item?.product?.code}`} disabled={noStock} onClick={() => handleAddAndSubsQuantity(item.amount + 1)}>
            <FaPlus size={20} />
            </button>
          </div>
        ) : (
          ''
        )}
      </div>

      

      <Snackbar open={openStockAlert} autoHideDuration={6000} onClose={() => setOpenStockAlert(false)}>
        <Alert onClose={() => setOpenStockAlert(false)} severity="warning">
          {'¡Solo ' + (item.product.stockAmount === 1 ? 'queda ' + item.product.stockAmount + ' unidad' : 'quedan ' + item.product.stockAmount + ' unidades') + ' en stock!'}
        </Alert>
      </Snackbar>
    </Box>
  );
};
